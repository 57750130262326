<template>
    <div class="employee-profile-page">
        <el-row>
            <el-col :span="24">
                <el-card shadow="always">
                    <el-form :inline="true" :model="searchFrom" class="demo-form-inline searchFrom" size="small">
                        <el-form-item label="岗位">
                            <el-select v-model="searchFrom.organizationName" clearable placeholder="请选择岗位">
                                <el-option label="全部 " value=""></el-option>
                                <el-option v-for="(item, index) in modelList" :key="index" :label="item.dictionaryName"
                                    :value="item.dictionaryCode"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="姓名">
                            <el-input v-model="searchFrom.organizationCode" :clearable="true"
                                placeholder="请输入姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话">
                            <el-input v-model="searchFrom.organizationtel" :clearable="true"
                                placeholder="请输入联系电话"></el-input>
                        </el-form-item>
                        <el-form-item label="机构">
                            <el-select v-model="searchFrom.institutions" clearable placeholder="请选择机构">
                                <el-option label="全部 " value=""></el-option>
                                <el-option v-for="item in companyList" :key="item.companyId" :label="item.companyName"
                                    :value="item.companyId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button icon="el-icon-search" type="primary" :disabled="disabledSearch"
                                @click="searchResule">查询</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>
        </el-row>
        <el-card class="card" shadow="always">
            <div>
                <el-button plain icon="el-icon-plus" type="primary" @click="add">添加</el-button>
                <el-button plain icon="el-icon-delete" type="danger" @click="handleDelete">删除</el-button>
            </div>
            <div class="table-block">
                <div class="Selectnum">已选中 <span style="color:#5479FF">{{ multipleSelection.length }}</span> 条信息</div>
                <el-table :data="tableData" ref="multipleTable" @row-click="handleClickTableRow" v-loading="loading"
                    :header-cell-style="{ 'text-align': 'center', 'background': '#EEF1FC', 'color': '#999' }"
                    :cell-style="{ 'text-align': 'center' }" style="width: 100%" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" fixed width="50"></el-table-column>
                    <!-- <el-table-column type="index" width="50" label="id"></el-table-column> -->
                    <el-table-column prop="noticeTitle" width="120" label="岗位"> </el-table-column>
                    <el-table-column prop="beginDate" width="150" label="姓名"> </el-table-column>
                    <el-table-column prop="noticeCompanyName" label="联系电话"> </el-table-column>
                    <el-table-column prop="templateName" label="机构"> </el-table-column>
                    <el-table-column label="操作" fixed="right" width="140">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row, true)" class="updatebutton" type="text"
                                size="small">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
                :total="total" background>
            </el-pagination>
        </el-card>
        <el-dialog :visible.sync="dialogProps.visible" width="70%" :title="dialogProps.title" :close-on-click-modal="false"
            @close="closedialog">
            <addemployee  @close="dialogProps.visible = false" v-if="dialogProps.visible" />
        </el-dialog>
    </div>
</template>
  
<script>
import { postRequest, postRequestAnthor } from '@/api'
import addemployee from './component/addemployee.vue'
export default {
    components: { addemployee },
    data() {
        return {
            searchFrom: {
                organizationName: '',
                organizationCode: '',
                organizationCode1: ''
            },
            tableData: [],
            total: null,
            pageNo: 1,
            pageSize: 10,
            loading: false,
            disabledSearch: false,
            info: {},
            companyList: [],
            multipleSelection: [],
            modelList: [],
            dialogProps: {
                visible: false,
                params: {},
                title: '新增简介'
            }
        }
    },
    mounted() {
        //   this.getAllNotice()

        //   postRequest('/dict/getDictByCategory/', {dictionaryCategoryCode: '10'}).then(res=>{
        //     console.log(res, '资讯模版')
        //     this.modelList = res
        //   })
    },
    methods: {
        add() {
            this.dialogProps = {
                visible:true,
                title:'新增简介'
            }
        },
        closeAddnotice() {
            // this.getAllNotice()
        },
        closedialog() {
            this.dialogProps = {
                visible:false
            }
        },
        searchResule() {
            this.pageNo = 1
            this.getAllNotice()
        },
        handleDelete() {
            if (this.multipleSelection.length <= 0) {
                this.$message({
                    message: '请选择删除项',
                    type: 'danger'
                })
            } else {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    let data = {
                        ids: [],
                        userId: '1'
                    }
                    this.multipleSelection.map(item => {
                        data.ids.push(item.id)
                    })
                    postRequest('/noticemanage/deletes', data).then(res => {
                        console.log(res, '删除')
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        })
                        this.getAllNotice()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        },
        handleClick(val, type) {
        },
        // 点击行选中
        handleClickTableRow(row) {
            this.$refs.multipleTable.toggleRowSelection(row);
        },
        // 选中项
        handleSelectionChange(val) {
            console.log(val);
            this.multipleSelection = val
        },
        getAllNotice() {
            this.disabledSearch = true
            this.loading = true
            let data = {
                noticeTitle: this.searchFrom.organizationName ? this.searchFrom.organizationName : '',
                noticeType: this.searchFrom.organizationCode ? this.searchFrom.organizationCode : '',
                noticeCompany: this.searchFrom.organizationCode1 ? this.searchFrom.organizationCode1 : '',
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }
            postRequestAnthor('/noticemanage/querynoticemanagelist', data).then(res => {
                console.log(res)
                this.total = res.count
                this.loading = false
                this.disabledSearch = false
                res.data.map(item => {
                    if (item.status == '1') {
                        item.state = '已推送'
                    } else {
                        item.state = '未推送'
                    }
                })
                this.tableData = res.data
            }).catch(err => {
                this.loading = false
                this.disabledSearch = false
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val
            this.getAllNotice()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageNo = val
            this.getAllNotice()
        }
    },
}
</script>
  
<style lang="scss" scoped>
.employee-profile-page {
    background: transparent;
}

.searchFrom>.el-form-item {
    margin-right: 30px !important;
}

.Selectnum {
    padding: 6px 30px;
    border: 1px solid #BFCBF4;
    font-size: 12px;
    color: #666;
    border-radius: 5px;
    margin-bottom: 10px;
}
</style>
  